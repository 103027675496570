
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiMagnify, mdiPlus, mdiFilter, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { isEqual, cloneDeep } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenu from '@/components/common/CommonExportMenu.vue'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'
import { useFilter } from '@/composables/useFilter'

import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { convertDotIntoCommaInput } from '@/utils/convertInput'
import { dateDotNotationToDash } from '@/utils/convertDate'

import { useDeleteDemand, useExportDemands, useGetDemandBasic, useGetDemands } from '@/api/demand'
import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useGetUsersBasic } from '@/api/user'

import { DemandOutput } from '@/api/types/demand'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/acquisition/views/demands/types'
import { Rights } from '@/api/types/right'
import { useNotify } from '@/store'

export default defineComponent({
  name: 'Demands',
  components: {
    CommonTableView,
    CommonExportMenu,
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonFilesDialog: () => import('@/components/common/CommonFilesDialog.vue'),
    AddEditDemandDialog: () => import('@/views/acquisition/views/demands/components/AddEditDemandDialog.vue'),
    DemandsFilterBar: () => import('@/views/acquisition/views/demands/components/DemandsFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const TABLE_HEADERS = [
      {
        text: root.$t('acquisition.demands.table.col.title.id'),
        value: 'id',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.demandDriver'),
        value: 'demandDriver.name',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.professionalUnit'),
        value: 'professionalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.demandProfile'),
        value: 'demandProfile',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.payGroup'),
        value: 'payGroup',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.description'),
        value: 'description',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.count'),
        value: 'count',
        sortable: true,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.createdAt'),
        value: 'createdAt',
        sortable: true,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('acquisition.demands.table.col.title.filesCount'),
        value: 'filesCount',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        width: 120,
        sortable: false,
      },
    ]

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof DemandOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'demandEnd',
      'demandProfile',
      'demandStart',
      'assignments',
    ]

    const isAddEditDemandDialogOpen = ref(false)

    const isEditMode = ref(false)

    const activeDemand = ref<DemandOutput | null>(null)
    const activeDemandToDuplicate = ref<DemandOutput | null>(null)

    function onClickEdit(_, { item: demand }: { item: DemandOutput }): void {
      if (hasSufficientRights(Rights.DEMAND_UPDATE) && hasSufficientRights(Rights.BASIC_READ)) {
        isEditMode.value = true

        isAddEditDemandDialogOpen.value = true

        activeDemand.value = { ...demand }
      }
    }

    const isDeleteDemandDialogOpen = ref(false)

    async function onClickDelete(demand: DemandOutput): Promise<void> {
      activeDemand.value = { ...demand }

      isDeleteDemandDialogOpen.value = true
    }

    const { deleteDemand } = useDeleteDemand()

    async function onDeleteDemand(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteDemand(activeDemand.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDemandDialogOpen.value = false

      activeDemand.value = null

      init()
    }

    function onClickDuplicateDemand(demand: DemandOutput): void {
      isAddEditDemandDialogOpen.value = true
      let updatedForm = deleteId({ ...demand })

      activeDemandToDuplicate.value = updatedForm
    }

    function deleteId<T extends { [key: string]: unknown }>(form: T): T {
      const updatedForm = cloneDeep(form)

      Object.entries(updatedForm).forEach(() => {
        delete updatedForm['id']
      })

      return updatedForm
    }

    const isRowInfoDialogOpen = ref(false)

    const isFilesDialogOpen = ref(false)

    function onClickInfo(demand: DemandOutput): void {
      activeDemand.value = { ...demand }

      isRowInfoDialogOpen.value = true
    }

    function onClickFiles(demand: DemandOutput): void {
      activeDemand.value = { ...demand }

      isFilesDialogOpen.value = true
    }

    function onCloseAddEditDeleteInfoFilesDialog(): void {
      isAddEditDemandDialogOpen.value = false

      isDeleteDemandDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isFilesDialogOpen.value = false

      isEditMode.value = false

      activeDemand.value = null
      activeDemandToDuplicate.value = null
    }

    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnitsBasic } = useGetProfUnitsBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()

    const { exec: getUsersBasic, data: users, isLoading: isLoadingUsersBasic } = useGetUsersBasic()

    const { exec: getDemandsBasic, data: demandsBasic, isLoading: isLoadingDemandsBasic } = useGetDemandBasic()

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (
          !profUnits.value?.length &&
          !orgUnits.value?.length &&
          !users.value?.length &&
          !demandsBasic.value?.length
        ) {
          getProfUnitsBasic()
          getOrgUnitsBasic()
          getUsersBasic()
          getDemandsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.DemandIds,
        value: null,
        label: root.$t('acquisition.demands.filters.demandIds'),
        items: computed(() => demandsBasic.value ?? []),
        isLoading: computed(() => isLoadingDemandsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.DemandDriverIds,
        value: null,
        label: root.$t('acquisition.demands.filters.demandDriver'),
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.DemandEndFrom,
        value: null,
        label: root.$t('acquisition.demands.filters.demandEndFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.DemandEndTo,
        value: null,
        label: root.$t('acquisition.demands.filters.demandEndTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('acquisition.demands.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren,
        value: null,
        label: root.$t('acquisition.demands.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIds,
        value: null,
        label: root.$t('acquisition.demands.filters.professionalUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren,
        value: null,
        label: root.$t('acquisition.demands.filters.professionalUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.DemandProfile,
        value: null,
        label: root.$t('acquisition.demands.filters.demandProfile'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.ActiveBy,
        value: null,
        label: root.$t('acquisition.demands.filters.demandClosedFrom'),
        items: [],
        dataTyp: FilterDataTypes.BooleanDate,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'createdAt', desc: true })

    const { exec: getDemands, data: demands, isLoading: isLoadingDemands, paginationResponse } = useGetDemands()

    const totalDemands = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getDemands({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    const { exportDemands, isLoading: isLoadingExport } = useExportDemands()

    async function onExportDemands(dateRange: { startMonth: string; endMonth: string }) {
      try {
        await exportDemands({
          startMonth: dateDotNotationToDash(dateRange.startMonth),
          endMonth: dateDotNotationToDash(dateRange.endMonth),
        })

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: demandNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<DemandOutput>(demands, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: demandTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<DemandOutput>(demands, () => init(filterFieldsObject.value))

    return reactive({
      icons: {
        mdiMagnify,
        mdiPlus,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        isAddEditDemandDialogOpen,
        isEditMode,
        activeDemand,
        activeDemandToDuplicate,

        isDeleteDemandDialogOpen,

        isRowInfoDialogOpen,

        isNotesDialogOpen,
        demandNotes,
        notesEntityId,

        isTasksDialogOpen,
        demandTasks,
        tasksEntityId,

        demands,
        isLoadingDemands,

        isFilterDropdownOpen,

        vuetifyTableOptions,

        totalDemands,

        isLoadingExport,

        isFilesDialogOpen,
      },
      listeners: {
        onClickEdit,
        onClickDuplicateDemand,

        onClickDelete,
        onDeleteDemand,

        onExportDemands,

        onClickInfo,

        onCloseAddEditDeleteInfoFilesDialog,

        onOpenNotesDialog,
        onReloadNotes,

        onOpenTasksDialog,
        onReloadTasks,

        onToggleFilterDropdown,

        onClickFiles,
      },
      functions: {
        debouncedCb,

        hasSufficientRights,

        convertDotIntoCommaInput,
      },
    })
  },
})
