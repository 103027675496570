import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  DemandOutput,
  DemandId,
  DemandInput,
  UseGetDemands,
  UseGetDemandBasic,
  UseCreateDemand,
  UseUpdateDemand,
  UseDeleteDemand,
  UseExportDemands,
} from '@/api/types/demand'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

const VERSION = 'v1'
const RESOURCE = 'demands'

function useGetDemands(): UseGetDemands {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetDemands['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetDemandBasic(): UseGetDemandBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateDemand(): UseCreateDemand {
  const axios = useAxios<PromiseType<ReturnType<UseCreateDemand['createDemand']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createDemand(data: DemandInput): Promise<DemandOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createDemand,
  }
}

function useUpdateDemand(): UseUpdateDemand {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateDemand['updateDemand']>>>({ method: 'PUT' })

  function updateDemand(id: DemandId, data: DemandOutput): Promise<DemandOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateDemand,
  }
}

function useDeleteDemand(): UseDeleteDemand {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteDemand['deleteDemand']>>>({ method: 'DELETE' })

  function deleteDemand(id: DemandId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteDemand,
  }
}

function useExportDemands(): UseExportDemands {
  const axios = useAxios<void>({ method: 'GET' })

  async function exportDemands(filters?: OptionalFiltersParam): Promise<void> {
    await axios.exec({ url: `/${VERSION}/${RESOURCE}/export`, params: filters })
  }

  return {
    ...axios,
    exportDemands,
  }
}

export { useGetDemands, useGetDemandBasic, useCreateDemand, useUpdateDemand, useDeleteDemand, useExportDemands }
