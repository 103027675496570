enum FILTER_FIELD_KEY {
  DemandIds = 'ids',
  DemandDriverIds = 'demandDriverIds',
  DemandEndFrom = 'demandEndFrom',
  DemandEndTo = 'demandEndTo',
  OrganizationalUnitIds = 'organizationalUnitIds',
  OrganizationalUnitIdsWithChildren = 'organizationalUnitIdsWithChildren',
  ProfessionalUnitIds = 'professionalUnitIds',
  ProfessionalUnitIdsWithChildren = 'professionalUnitIdsWithChildren',
  DemandProfile = 'demandProfile',
  ActiveBy = 'activeBy',
}

export { FILTER_FIELD_KEY }
